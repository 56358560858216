import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from '../../../../theme';

const Service = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const [serviceVideosNumber, setServiceVideosNumber] = useState(0);

    useEffect(() => {
      const serviceVideosNumber = Math.floor(Math.random() * 5);
      setServiceVideosNumber(serviceVideosNumber + 1);
    }, []);

    return (
      <section
        id={id}
        ref={ref}
        className="m-auto mt-8 flex max-w-xl flex-col items-end"
      >
        <div>
          <div className=" mb-6 flex flex-col border-l-[6px] border-primary-500 pl-4 lg:mb-8">
            <Text
              as="h1"
              className="text-black-500 mb-4 inline font-Rubik text-2xl font-bold leading-[30px] text-gray-primary lg:text-[38px] lg:leading-big"
            >
              {t(
                'The New Normal of Development Solves Real Problems',
              )}
            </Text>
            <Text
              as="p"
              className=" mr-0 font-Poppins font-medium text-gray-secondary lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
              {...typography.medium}
            >
              {t(
                'Whether it is a startup that wants to take on a social challenge or a company that wants to promote DX or launch a new business.a major issue is a lack of execution capability due to limited knowledge and a weak development system.',
              )}
            </Text>
          </div>

          {serviceVideosNumber !== 0 && (
            <video
              playsInline
              muted
              height={'100%'}
              width={'1144px'}
              data-testid="video-asset"
              role="video"
              className="3xl:w-full"
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/videos/service${serviceVideosNumber}.mp4`}
              controls={false}
              autoPlay={true}
              loop={true}
            />
          )}

          <Text
            as="p"
            className="mb-8 mt-6 mr-0 text-left font-Poppins text-gray-secondary lg:mt-8 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
            {...typography.medium}
          >
            {t(
              'We apply our empathy, understanding, and accumulated insights based on our extensive experience in business development to build operations and systems specialized for business co-creation. We lead development with an evolved methodology and mindset that fits the trends and values of the era.',
            )}
          </Text>
        </div>
      </section>
    );
  },
);

export { Service };
