import { useRouter } from "next/router";
import { useEffect } from "react";

import { pageview } from "./gtag";

export const usePageView = () => {
    const router = useRouter();
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleRouteChange = (url: string, { shallow }: any) => {
            if (!shallow) {
                pageview(url);
                window.location.reload();
            }
        };

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
};
