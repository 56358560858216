import Link from 'next/link';
import { useMediaQuery } from '@chakra-ui/react';

import { LanguageProvider } from 'components/molecules';
import {
  CloseIcon,
  Menu,
  MenuLight,
  LogoLgLight,
  LogoLgDark,
  LogoSM,
} from 'public/assets/images/svg';

type Props = {
  isNavExpanded?: boolean;
  isFooterActive?: boolean;
  handleNavExpanded?: () => void;
  setNavExpanded: Function;
};

export const HeaderTop = ({
  isNavExpanded,
  isFooterActive,
  handleNavExpanded,
  setNavExpanded
}: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <div
      className={`m-auto flex max-w-[1440px] items-center justify-between transition-all ${
        isNavExpanded && 'w-full px-6 py-8 lg:px-[30px] xl:px-[60px]'
      }`}
    >
      <div className="inline-block cursor-pointer" onClick={()=>setNavExpanded(false) }>
        <Link href="/" passHref>
          <a>
            {isNavExpanded || isFooterActive ? (
              isMobile ? (
                <LogoSM />
              ) : (
                <LogoLgDark />
              )
            ) : (
              <LogoLgLight />
            )}
          </a>
        </Link>
      </div>
      <div className="inline-flex items-center justify-between gap-0 md:gap-4">
        {!(isMobile && !isNavExpanded) && (
          <LanguageProvider
            isFooterActive={isFooterActive}
            isNavExpanded={isNavExpanded}
          />
        )}

        {isNavExpanded ? (
          <div
            onClick={handleNavExpanded}
            className="cursor-pointer text-white"
          >
            <CloseIcon />
          </div>
        ) : (
          <div
            onClick={handleNavExpanded}
            className={`cursor-pointer`}
          >
            {isFooterActive ? <MenuLight /> : <Menu />}
          </div>
        )}
      </div>
    </div>
  );
};
