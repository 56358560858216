import { INavbarConfig } from 'shared/interfaces/';

export const navBarConfig: INavbarConfig[] = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Service',
    path: '/service',
  },
  {
    title: 'Technology',
    path: '/technology',
  },
  {
    title: 'Quality',
    path: '/quality',
  },
  {
    title: 'Security',
    path: '/security',
  },
  {
    title: 'Company',
    path: '/company',
  },
  {
    title: 'Career',
    path: '/career',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
];
