import { INews } from 'shared/interfaces/news';
// import { Trans } from 'react-i18next';

export const NEWS: INews[] = [
  // {
  //   id: '1',
  //   title: 'Notice Regarding Change of CEO',
  //   thumbnail: '',
  //   cover: '',
  //   link: '/news/1',
  //   slug: 'ceo-change-2023',
  //   meta: {
  //     title:
  //       'Notice of Change in Representative Director - News - wesionaryTEAM',
  //     keywords: 'Notice',
  //     description: 'Notice Regarding Change of CEO',
  //   },
  //   description: {
  //     en: (
  //       <div className="grid gap-4">
  //         <p>
  //           <Trans>Dear valued business partners,</Trans>
  //         </p>
  //         <p>
  //           <Trans>
  //             We hope this letter finds you well. We would like to
  //             express our sincere gratitude for your continued
  //             support.
  //           </Trans>
  //         </p>
  //         <p>
  //           <Trans>
  //             We are pleased to inform you that effective December
  //             31,2022, our company has appointed Ichiro Nagata as the
  //             new representative director. We will continue to work
  //             together as a team to accelerate our vision of business
  //             collaboration.
  //           </Trans>
  //         </p>
  //         <p> Thank you for your continued patronage.</p>
  //         <p>Sincerely,</p>
  //         <p>New Representative Director: Ichiro Nagata</p>
  //       </div>
  //     ),
  //     ja: (
  //       <div className="grid gap-4">
  //         <p>お取引先各位</p>
  //         <p>
  //           <span className="block">
  //             拝啓 ますますご清栄のこととお喜び申し上げます。
  //           </span>
  //           平素は格別のご高配を賜り、厚く御礼申し上げます。
  //         </p>
  //         <p>
  //           <span className="block">
  //             2022年12月31日をもちまして、下記のとおり当社代表者を変更し、新代表取締役として永田一郎が就任致しましたことをお知らせします。
  //           </span>
  //           引き続き、社員一丸となって弊社のビジョンである事業共創を加速するため尽力してまいりますので、今後ともご愛顧賜りますようお願い申し上げます。
  //         </p>
  //         <p>敬具</p>
  //         <p>
  //           <span className="block">記</span>
  //           ＜新代表者＞ 代表取締役 永田 一郎
  //         </p>
  //         <p>以上</p>
  //       </div>
  //     ),
  //   },
  //   date: '2023 Feb',
  //   label: 'Notice',
  // },
  {
    id: '2',
    title:
      'Released a music event companion matching service “MELD”.',
    thumbnail: 'card2.png',
    cover: 'cover2.png',
    link: '/news/2',
    slug: 'meld-release',
    meta: {
      title:
        'MELD Music Event Companion Matching Service - News - wesionaryTEAM',
      keywords:
        'new business co-creation, new business development, system development',
      description:
        'We have released the “MELD” music event companion matching service.',
    },
    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            「
            <a
              href="https://meld.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              MELD（メルド）
            </a>
            」は音楽のインディペンデント系レーベルを主力事業とする株式会社MELDの初となる自社サービスになります。
          </p>
          <p>
            既存の事業を展開している中で「一人」であることを理由にライブやフェスに参加できないとの声が多くあります。
          </p>
          <p>
            より多くの人に音楽イベントを楽しんで頂けるよう、信頼でき、簡単に、趣味が近い人とつながるサービスを開発しました。
          </p>
          <p>
            2023年2月にMVP（必要最小限の機能）をリリースし、アイディアの検証をスタートしました。
          </p>
          <p>
            利用者からのフィードバックと利用状況をモニタリングしながら、機能とユーザー体験を高めて参ります。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            We will continue to enhance our service's functionality
            and user experience, while monitoring feedback from users
            and usage statistics.
          </p>
          <p>
            This new service is the first in-house service of{' '}
            <a
              href="https://meld.co.jp/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              MELD Co., Ltd.
            </a>
            , which is mainly engaged in independent record labels.
          </p>
          <p>
            While expanding their existing business, many people have
            expressed their inability to participate in live
            performances and festivals due to being "alone.”
          </p>
          <p>
            We developed a service that allows people to easily and
            reliably connect with others who share similar hobbies, in
            order to make music events more enjoyable for a larger
            audience.
          </p>
          <p>
            In February 2023, we released our MVP (Minimum Viable
            Product) to start validating our idea.
          </p>
          <p>
            We will continue to enhance our service's functionality
            and user experience, while monitoring feedback from users
            and usage statistics.
          </p>
        </div>
      ),
    },
    date: '2023 Feb',
    label: 'New business co-creation',
  },
  {
    id: '3',
    title: 'Release a corporate data search service ”BizRiz”.',
    thumbnail: 'card3.png',
    cover: 'cover3.png',
    link: '/news/3',
    slug: 'bizriz-release',
    meta: {
      title:
        'BizRiz Corporate Data Search Service - News - wesionaryTEAM',
      keywords: 'DX, business efficiency, system development',
      description:
        'We have released the corporate data search service “BizRiz”.',
    },

    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            「BizRiz（ビズリズ）」ダイレクトマーケティングを主力事業とする{' '}
            <a
              href="https://www.nd-promo.co.jp/e-2.html"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              日本ダイレクトプロモーションと
            </a>
            共に、大規模な企業データベースを利用できるサービスになります。
          </p>
          <p>
            これまではメールや電話でお客様からご依頼を受け、該当するデータを抽出し提供しておりました。
          </p>
          <p>
            多くの手間と時間を要する上、お客様が事前にデータを確認できないなどの問題があり、事業の拡大と顧客体験において課題となっていました。
          </p>
          <p>
            BizRiz
            によって企業自身がデータを検索し、確認し、ダウンロードできるようになりました。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            "BizRiz（ビズリズ)" is a service that can utilize a large
            corporate database in conjunction with{' '}
            <a
              href="https://www.nd-promo.co.jp/e-2.html"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Japan Direct Promotion
            </a>
            , which specializes in direct marketing as its main
            business.
          </p>
          <p>
            Until now, request were received from the customer through
            emails or phone cal, and corresponding data were extracted
            and provided to the respective customers.
          </p>
          <p>
            There were problems such as requiring a lot of time and
            effort and customers being unable to confirm the data in
            advance, which became challenges in expanding the business
            and improving customer experience.
          </p>
          <p>
            With BizRiz, companies can now search for, confirm, and
            download data themselves.
          </p>
        </div>
      ),
    },
    date: '2023 Jan',
    label: 'DX',
  },
  {
    id: '4',
    title:
      'Released a a Q&A service created together with fans “Question Market (Quema)”.',
    thumbnail: 'card4.png',
    cover: 'cover4.png',
    link: '/news/4',
    date: '2022 Dec',
    slug: 'question-market-release',
    meta: {
      title:
        'Question Market - Q&A Service Created With Fans - News - wesionaryTEAM',
      keywords:
        'New Business Co-creation, New Business Development, System Development',
      description:
        'We have released “Question Market”, a Q&A service created with fans.',
    },

    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            <a
              href="https://pyxis.company/company/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              株式会社ピクシス{' '}
            </a>
            <span>と新しいモデルのQ＆Aサービス「</span>
            <a
              href="https://question.market/"
              target={'_blank'}
              rel="noopener noreferrer"
              className="inline-block  text-primary-500 hover:underline"
            >
              Question Market
            </a>
            」 をリリースしました。
          </p>
          <p>
            インフルエンサーはさまざまなファンからの質問へ答えることで収益を得ることができます。過去にインフルエンサーが答えた質問を他のファンが閲覧（有料）した際に、インフルエンサーと質問したファンは収益が得られます。
          </p>
          <p>
            ビジネスモデル特許を取得している、これまでにない新たな試みになります。事業アイディアを検証するため、必要最小限の機能（MVP）をリリースしました。
          </p>
          <p>
            インフルエンサー、利用者からフィードバック、利用データをもとにプロダクトを改良し、マーケットフィットを目指して参りします。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Together with{' '}
            <a
              href="https://pyxis.company/company/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              Pyxis Co., Ltd.
            </a>
            , we have released a new model Q&A service called "
            <a
              href="https://question.market/"
              target={'_blank'}
              rel="noopener noreferrer"
              className="inline-block  text-primary-500 hover:underline"
            >
              Question Market
            </a>
            ".
          </p>
          <p>
            Influencers can earn revenue by answering questions from
            various fans. When third party fans (for a certain charge)
            view questions that influencers have answered in the past,
            both the influencer and the fan who asked the respective
            question can earn revenue.
          </p>
          <p>
            This is a new attempt that has obtained a business model
            patent. We have released the minimum viable product (MVP)
            to verify the business idea.
          </p>
          <p>
            We will improve the product based on feedback and usage
            data from influencers and users, and aim for market fit.
          </p>
        </div>
      ),
    },
    label: 'New business co-creation',
  },
  {
    id: '5',
    title: 'Notice Regarding Office Opening',
    thumbnail: 'card5.png',
    cover: 'cover5.png',
    link: '/news/5',
    date: '2022 Oct',
    slug: 'new-office-2022',
    meta: {
      title:
        'Notice regarding office opening - News - wesionaryTEAM',
      keywords: 'Notice',
      description: 'Notice Regarding Office Opening',
    },

    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            弊社では新型コロナウイルス感染拡大の予兆が見られた2020年5月以降、フルリモート体制へと移行しておりましたが、直近での社会情勢の変化を踏まえハイブリッド体制へと一部移行するに加え、今後の組織の拡大を見据えて最大80人が勤務できるオフィスを開設致しました。
          </p>
          <p>
            メンバー一同、オフィス勤務とリモートワークの両方をバランス良く活用しながら、共創による事業開発に一層精進していく所存でございます。
          </p>
          <p>
            今後とも変わらぬご支援を賜りますようお願い申し上げます。
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Since May 2020, when we saw signs of the spread of the new
            coronavirus, our company has transitioned to a fully
            remote system. However, in light of recent changes in the
            social situation, we have partially transitioned to a
            hybrid system and have opened an office that can
            accommodate up to 80 people, with the aim of expanding our
            organization in the future.
          </p>
          <p>
            Our team intends to balance office work and remote work,
            making the best use of both, and further devote ourselves
            to business development through collaboration.
          </p>
          <p>
            We hope to continue receiving your unwavering support in
            the future.
          </p>
        </div>
      ),
    },
    label: 'Notice',
  },
  {
    id: '6',
    title:
      'Released an unique domestic NFT service “META PYLON”',
    thumbnail: 'card6.png',
    cover: 'cover6.png',
    link: '/news/6',
    slug: 'meta_pylon',
    meta: {
      title:
        'Unique Domestic NFT Service “META PYLON” - News - wesionaryTEAM',
      keywords: 'New Business Co-creation, New Business Development, System Development',
      description:
        'We have released a unique domestic NFT service “META PYLON”',
    },
    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            クリエイティブ事業を主力事業とする{' '}
            <a
              href="https://wooltrakey.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              WoOLTRAKEY株式会社
            </a>
            と全く新しいNFTアートプロジェクト「{' '}
            <a
              href="https://metapylon.io/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              META PYLON
            </a>
            」をスタートしました。
          </p>
          <p>
            META PYLON
            のホルダーはウォレットに保有するあらゆるNFTコレクションを三角コーンに自由自在に貼り付け、カスタマイズしたNFTを入手できます。3D
            と NFT
            により新しいアートの体験を創り出すことを目指しています。
          </p>
          <p>
            3DのパイロンにNFTを貼り付ける仕組みの特許を取得しました。
          </p>

          <video
            height={'100%'}
            width={'1144px'}
            role="video"
            className="3xl:w-full"
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/meta.mp4`}
            controls={true}
          />
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Together with{' '}
            <a
              href="https://wooltrakey.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              WoOLTRAKEY Inc.
            </a>
            , a company that specializes in creative businesses, we
            have launched a brand new NFT art project called "
            <a
              href="https://metapylon.io/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              META PYLON
            </a>
            ".
          </p>
          <p>
            META PYLON holders can freely attach any NFT collection
            they own in their wallet to the triangular cones and
            obtain customized NFTs. We aim to create a new art
            experience through 3D and NFT.
          </p>
          <p>
            We have obtained a patent for the mechanism of attaching
            NFTs to 3D pylons.
          </p>

          <video
            height={'100%'}
            width={'1144px'}
            role="video"
            className="3xl:w-full"
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/meta.mp4`}
            controls={true}
          />
        </div>
      ),
    },
    date: '2022 Sep',
    label: 'New business co-creation',
  },
  {
    id: '7',
    title:
      'Released “SOUDAN”, a marketplace for skills & knowledge.',
    thumbnail: 'card7.png',
    cover: 'cover7.png',
    link: '/news/7',
    date: '2022 Jul',
    slug: 'soudan-release',
    meta: {
      title:
        'Soudan - Marketplace for Skills and Knowledge - News - wesionaryTEAM',
      keywords: 'co-creation of new businesses, new business development, system development',
      description:
        'We have released “Soudan”, a marketplace for skills and knowledge.',
    },
    description: {
      ja: (
        <div className="grid gap-4">
          <p>
            「新しい体験をあなたの人生に。」をミッションとする{' '}
            <a
              href="https://www.ito-technologies.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ITO Technologies株式会社
            </a>
            とスキルや知識を売買できる「Soudan」サービスのβ版をリリースしました。
          </p>
          <p>
            リモート通話で英会話、占い、恋愛相談、雑談、悩み相談などについて1on1で相談できます。
          </p>
          <p>
            また、自分のスキルや経験で多くの人の役に立てながら、収益を得る仕組みを提供しています。
          </p>
          <p>
            現在、ユーザーに使って頂きながら、正式版のリリースに向けて、機能や体験を改良しております。
          </p>
          <p className="mt-10">
           iOS アプリ <br />
            <a
              href="https://apps.apple.com/jp/app/soudan-%E5%8D%A0%E3%81%84-%E6%81%8B%E6%84%9B%E7%9B%B8%E8%AB%87-%E9%9B%91%E8%AB%87-%E6%82%A9%E3%81%BF%E7%9B%B8%E8%AB%87%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%A2%E3%83%97%E3%83%AA/id1580214612"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ストアで見る
            </a>
          </p>
          <p className="mt-10">
           Android アプリ<br />
            <a
              href="https://play.google.com/store/apps/details?id=com.ito_technologies.soudan&hl=ja&gl=US"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ストアで見る
            </a>
          </p>
        </div>
      ),
      en: (
        <div className="grid gap-4">
          <p>
            Together with{' '}
            <a
              href="https://www.ito-technologies.com/"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ITO Technologies Co., Ltd.
            </a>{' '}
            whose mission is to bring new experiences to user’s life,
            we have released the beta version of the "Soudan" service
            where skills and knowledge can be bought and sold.
          </p>
          <p>
            With remote calling, you can have 1-on-1 consultations
            about English conversation session, fortune-telling, love
            advice, small talk, and problem-solving.
          </p>
          <p>
            Similarly, we are also offering a environment where user
            can earn revenue while using one's own skills and
            experience to benefit many people.
          </p>
          <p>
            Currently, we are improving our features and user
            experience towards the official release while working on
            the feedbacks from the current user of our application.
          </p>
          <p className="mt-10">
            iOS App <br />
            <a
              href="https://apps.apple.com/jp/app/soudan-%E5%8D%A0%E3%81%84-%E6%81%8B%E6%84%9B%E7%9B%B8%E8%AB%87-%E9%9B%91%E8%AB%87-%E6%82%A9%E3%81%BF%E7%9B%B8%E8%AB%87%E3%81%A7%E3%81%8D%E3%82%8B%E3%82%A2%E3%83%97%E3%83%AA/id1580214612"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              View in store
            </a>
          </p>
          <p className="mt-10">
            Android App <br />
            <a
              href="https://play.google.com/store/apps/details?id=com.ito_technologies.soudan&hl=ja&gl=US"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              View in store
            </a>
          </p>
        </div>
      ),
    },
    label: 'New business co-creation',
  },
  {
    id: '8',
    title:
      'Renewal of Kansai Regional Alliance, Kansai Startup Ecosystem’s website',
    thumbnail: 'card8.png',
    cover: 'cover8.png',
    link: '/news/8',
    date: '2022 May',
    slug: 'kansai-renewal',
    meta: {
      title:
        'Kansai Wide Area Union Kansai Startup Ecosystem Website - News - wesionaryTEAM',
      keywords: 'DX, Business Efficiency, System Development',
      description:
        'The Kansai Wide Area Union Kansai Startup Ecosystem website has been renewed.',
    },
    description: {ja:(
      <div className="grid gap-4">
        <p>
          <a
            href="https://www.kouiki-kansai.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            関西広域連合
          </a>
          の重大構想である「日本有数の研究開発型スタートアップの地
          ディープテックバレー“KANSAI”」のブランド確立と情報発信力を高めるため、{' '}
          <a
            href="https://www.forstartups.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            フォースタートアップス株式会社
          </a>
          と共同で「{' '}
          <a
            href="https://kansai-startup-ecosystem.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            関西スタートアップ・エコシステム
          </a>
          」を刷新しました。
        </p>
        <p>
          これまで、情報のアップデートに時間がかかっていたり、その過程で多くのアナログ業務が発生していました。
        </p>
        <p>
          今回のシステム刷新により、関西広域連合の各自体の担当者が円滑にスタートアップ関連のイベントやお知らせ、記事を配信できるようになりました。
        </p>
        <p>
          アクセスと利用状況を踏まえてサイトを改良しながら、SEO対策の強化によって、より多くのターゲットユーザーがコンテンツにふれる機会を増やして参ります。
        </p>
      </div>
    ),en:(
      <div className="grid gap-4">
        <p>
        As part of the{" "}
          <a
            href="https://www.kouiki-kansai.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
           Kansai Regional Alliance
          </a>
          's major plan, "KANSAI - the Deep Tech Valley for Japan's leading research and development startups," efforts have been made to establish the brand and enhance the initiative's information dissemination. In collaboration with{" "}
          <a
            href="https://www.forstartups.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            For Startups Inc.
          </a>
          ., the "
          <a
            href="https://kansai-startup-ecosystem.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
           Kansai Startup Ecosystem
          </a>
          " has been revamped.
        </p>
        <p>
        Until now, updating information had taken time and had resulted in many analog tasks during the process.
        </p>
        <p>
        With this system renewal, representatives of each organization within the Kansai Wide Area Union can now smoothly distribute events, announcements, and articles related to start-ups.
        </p>
        <p>
        We will improve the website based on access and usage and enhance SEO to increase the opportunities for more target users to engage with the content.
        </p>
      </div>
    )},
    label: 'DX',
  },
  {
    id: '9',
    title:
      'Released influencer matching service “Beee”.',
    thumbnail: 'card9.png',
    cover: 'cover9.png',
    link: '/news/9',
    date: '2022 Feb',
    slug: 'beee-release',
    meta: {
      title:
        'Beee Influencer Matching Service - News - wesionaryTEAM',
      keywords: 'new business co-creation, new business development, system development',
      description:
        'We have released the influencer matching service “Beee”.',
    },
    description: {ja:(
      <div className="grid gap-4">
        <p>
          ファッション業界のサステナビリティに取り組んでいる{' '}
          <a
            href="https://kol-tech.co.jp/company/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            株式会社KOLテクノロジーズ
          </a>
          とインフルエンサー・マッチング・サービス「{' '}
          <a
            href="https://www.c.beee.kol-tech.co.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            Beee（ビー）
          </a>
          」を開発しました。
        </p>
        <p>
          発信力を持つインフルエンサーと、商品やイベントの体験談を発信してほしい企業さまを、A.I（人工知能）の技術でマッチングするサービスです。
        </p>
        <p>
          ひとまず必要最小限の機能（MVP）からスタートし、企業とインフルエンサーを増やしながらマーケットフィットに向けてプロダクトを改良する予定です。
        </p>
      </div>
    ),en:(
      <div className="grid gap-4">
        <p>
        In collaboration with{' '}
          <a
            href="https://kol-tech.co.jp/company/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
           KOL Technologies.inc
          </a>
          , a company that focuses on sustainability in the fashion industry, we have developed the influencer matching service "
          <a
            href="https://www.c.beee.kol-tech.co.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            Beee
          </a>
          ”.
        </p>
        <p>
        Our service uses artificial intelligence (AI) technology to match companies who want influencers with strong communication skills to share their product or event experiences.
        </p>
        <p>
        Our plan is to start with the minimum viable product (MVP) and gradually improve it towards market fit as we increase the number of companies and influencers.
        </p>
      </div>
    )},
    label: 'New business co-creation',
  },
  {
    id: '10',
    title:
      'Released “Health Journey”, a PHR app that specializes in infant health checkups and school health checkups.',
    thumbnail: 'card10.png',
    cover: 'cover10.png',
    link: '/news/10',
    date: '2022 Feb',
    slug: 'health-journey-release',
    meta: {
      title:
        'Health Journey - PHR app specialized in infant and school health checkups - News - wesionaryTEAM',
      keywords: 'DX, business efficiency, system development',
      description:
        'We have released “Health Journey,” a PHR app specialized in infant and school health checkups.',
    },
    description: {ja:(
      <div className="grid gap-4">
        <p>
          病院の診療情報や、健康診断の情報を電子化の電子化に長く取り組み多数の実績を持つ、{' '}
          <a
            href="https://rwdata.co.jp/about/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            リアルワールドデータ株式会社
          </a>
          のPHRアプリをリニュアルしました。
        </p>
        <p>
          今回のリニューアルでは、健診結果の閲覧や共有の体験を向上することに注力しました。
        </p>
        <p>
          次に健診結果に対する解説の追加や、健診に関する自治体からのタイムリーなお知らせの実現を目指して参ります。
        </p>
        <div className="my-10 flex gap-8">
          <img
            width={'25%'}
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_ios.png`}
            className="  border border-solid border-gray-500"
            alt="news"
          />

          <div>
            <div>iOS アプリ</div>
            <a
              href="https://apps.apple.com/app/id1599442495"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ストアで見る
            </a>
          </div>
        </div>
        <div className="my-10 flex gap-8">
          <img
            width={'25%'}
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_android.png`}
            className="  border border-solid border-gray-500"
            alt="news"
          />
          <div>
            <div>Android アプリ</div>
            <a
              href="https://play.google.com/store/apps/details?id=jp.co.shrcenter.HealthJourney"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
              ストアで見る
            </a>
          </div>
        </div>
      </div>
    ),
    en:(
      <div className="grid gap-4">
        <p>
        Together with {' '}
          <a
            href="https://rwdata.co.jp/about/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            Real World Data Inc.
          </a>
          , a company with extensive experience in digitizing hospital medical information and health examination data, we have renewed it’s existing PHR app.
        </p>
        <p>
        For this renewal, we focused on enhancing the experience of viewing and sharing health check results.
        </p>
        <p>
        Our next goal is to provide explanations for health examination results & achieve timely notifications regarding health checkups from local governments.
        </p>
        <div className="my-10 flex gap-8">
          <img
            width={'25%'}
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_ios.png`}
            className="  border border-solid border-gray-500"
            alt="news"
          />

          <div>
            <div>iOS App</div>
            <a
              href="https://apps.apple.com/app/id1599442495"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
             View in store
            </a>
          </div>
        </div>
        <div className="my-10 flex gap-8">
          <img
            width={'25%'}
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/HJ_android.png`}
            className="  border border-solid border-gray-500"
            alt="news"
          />
          <div>
            <div>Android App</div>
            <a
              href="https://play.google.com/store/apps/details?id=jp.co.shrcenter.HealthJourney"
              target={'_blank'}
              rel="noopener noreferrer"
              className=" text-primary-500 hover:underline"
            >
               View in store
            </a>
          </div>
        </div>
      </div>
    )
      },
    label: 'DX',
  },
  {
    id: '11',
    title:
      'Released “Co-Workation”, a platform for collaborative experiences.',
    thumbnail: 'card11.png',
    cover: 'cover11.png',
    link: '/news/11',
    date: '2021 Sep',
    slug: 'co-workation-release',
    meta: {
      title: 'co-workation Experience Co-Creation Platform - News - wesionaryTEAM',
      keywords: 'new business co-creation, new business development, system development',
      description:
        'We have released the experience co-creation platform “co-workation”.',
    },
    description: {ja:(
      <div className="grid gap-4">
        <p>
          組織と働く人に寄り添い働く環境のUpdateをミッションとする{' '}
          <a
            href="https://perkup.life/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            PerkUP株式会社
          </a>
          と法人向け共創型ワーケーションプラットフォーム「{' '}
          <a
            href="https://co-workation.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            コワーケーション / co-workation
          </a>
          」をスタートしました。
        </p>
        <p>
          共に(CO)働く(WORK)オフィスだけではない滞在体験(WORKATION)を創り、”働く環境改革”の促進を目指しています。
        </p>
        <p>
          構想は非常に大きく、企画者、施設提供者、コンテンツ提供者との連携が必要となる新しい試みになります。
        </p>
        <p>
          ひとまず最小限の機能をリリースし、マーケットにニーズを掴みながら、知見を蓄積し、プロダクトを改良して参ります。
        </p>
        <img
          src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/cw.png`}
          className="m-auto 
                w-full border border-solid border-gray-500 object-cover object-center"
          alt="news"
        />
      </div>
    ),en:(
      <div className="grid gap-4">
        <p>
        We released{' '}
        <a
            href="https://co-workation.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            コワーケーション / co-workation
          </a>
        
          , a co-creation work platform for corporate clients, in collaboration with {' '}
          <a
            href="https://perkup.life/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
          PerkUP Inc.
          </a>
         
          , whose mission is to update the working environment by being close to the organization and its workers.
        </p>
        <p>
        Our goal is to create a unique stay experience called "workation" that goes beyond the traditional office setting, and to promote the "work environment reform".
        </p>
        <p>
        This is a huge project that requires collaboration between planners, facility providers, and content providers.
        </p>
        <p>
        Currently, we have released minimum necessary features, accumulate the needs of the market, knowledge, and improve the product.
        </p>
        <img
          src={`${process.env.NEXT_PUBLIC_STATIC_URL}/news/cw.png`}
          className="m-auto 
                w-full border border-solid border-gray-500 object-cover object-center"
          alt="news"
        />
      </div>
    )},
    label: 'New business co-creation',
  },
  {
    id: '12',
    title:
      'Development of a medical diagnosis digitalization(DX) platform “Porous” & started trial use at medical institutions.',
    thumbnail: 'card12.png',
    cover: 'cover12.png',
    link: '/news/12',
    date: '2021 Jul',
    slug: 'porous-release',
    meta: {
      title:
        'Porous Medical Diagnosis DX Platform - News - wesionaryTEAM',
      keywords: 'DX, Business Efficiency, System Development',
      description:
        'We have developed the medical diagnosis DX platform “Porous” and started trials in medical institutions.',
    },
    description: {ja:(
      <div className="grid gap-4">
        <p>
          医療関連のソフトウェア開発を主力事業とするBonBon株式会社と医療診断のDX化とAIによる簡略化ができる「Porous」を開発しました。
        </p>
        <p>
          症例画像の共有やレビュー、コメントのやり取り、レポートの作成や承認など、多岐にわたる業務をカバーしております。
        </p>
        <p>
          複数の医療機関で試用して頂いております。フィードバックや利用状況を踏まえて、プロダクトを改良して参りします。
        </p>
      </div>
    ),en:(
      <div className="grid gap-4">
        <p>
        Together with BonBon Co., Ltd., whose main business is medical-related software development, we have developed "Porous", a medical diagnosis digitalization(DX) platform which enables the simplification of medical diagnosis through digitalization & AI.
        </p>
        <p>
        Our platform covers a wide range of tasks, including sharing and reviewing medical images, exchanging comments, creating and approving reports, and more.
        </p>
        <p>
        Our product is being tested in multiple medical institutions. Based on feedback and usage from users, we are working to improve the product & user experience .
        </p>
      </div>
    )},
    label: 'DX',
  },
  {
    id: '13',
    title:
      'Released “TeamPlace” a workplace platform that connects people.',
    thumbnail: 'card13.png',
    cover: 'cover13.png',
    link: '/news/13',
    date: '2021 May',
    slug: 'teamplace-release',
    meta: {
      title:
        'TeamPlace “Workplace platform that connects people” - News - wesionaryTEAM',
      keywords: 'New Business Co-creation, New Business Development, System Development',
      description:
        'We have released “TeamPlace”, a workplace platform that connects people.',
    },
    description: {ja:(
      <div className="grid gap-4">
        <p>
          世界中の誰もが、どこでも豊かに働き生きられる社会」の実現をビジョンとする{' '}
          <a
            href="https://anywhere.co.jp/company"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            株式会社AnyWhere
          </a>
          と国内初の「人でつなげる」ことを特徴するワークプレイスプラットフォーム「{' '}
          <a
            href="https://team-place.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            TeamPlace
          </a>
          」の提供を開始しました。
        </p>
        <p>
          ワークプレイスの空間や関わる人を可視化し、人のつながりや新しいコラボレーションを生むきっかけを創ることを目指しています。
        </p>
        <p>TeamPlace は新しく大きな構想となります。</p>
        <p>
          コアな機能を先行してリリースしました。スペース運営者、スペース利用者からフィードバックを頂きながらサービスの提供価値を高めて参りします。
        </p>
      </div>
    ),en:(
      <div className="grid gap-4">
        <p>
        In collaboration with{' '}
          <a
            href="https://anywhere.co.jp/company"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            Anywhere Inc.
          </a>
          , which aims to achieve a vision of "a society where everyone in the world can work and live richly anywhere," in Japan we have launched its first workplace platform, "
          <a
            href="https://team-place.com/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            TeamPlace
          </a>
          ". The platform connects people and is designed to enable remote and flexible working arrangements.
        </p>
        <p>
        Our aim is to visualize the workplace environment and people involved, and create opportunities for new collaborations and connections.
        </p>
        <p>TeamPlace is a new and ambitious project.</p>
        <p>
        We have released the core features first and will continue to enhance the value of the service based on feedback from space operators and users.
        </p>
      </div>
    )},
    label: 'New business co-creation',
  },
  {
    id: '14',
    title:
      'Released a business support service called “GIMMY” that specializes in personal trainers and sports coaches.',
    thumbnail: 'card14.png',
    cover: 'cover14.png',
    link: '/news/14',
    date: '2021 Jan',
    slug: 'gimmy-release',
    meta: {
      title:
        'GIMMY, a business support service specializing in personal trainers and sports coaches - News - wesionaryTEAM',
      keywords: 'DX, business efficiency, system development',
      description:
        'We have released “GIMMY”, a business support service specializing in personal trainers and sports coaches.',
    },
    description: {ja:(
      <div className="grid gap-4">
        <p>
          スポーツやフィットネス関連サービスを主力事業とする{' '}
          <a
            href="https://rascals.co.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            株式会社RASCAL's
          </a>
          とパーソナルトレーニングに関する業務をDX化するため「{' '}
          <a
            href="https://gimmy.co/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            GIMMY
          </a>
          」プラットフォームの提供を開始しました。
        </p>
        <p>
          当業界ではITに不慣れな方が多い上、DX化の余地が多く残されています。
        </p>
        <p>
          顧客管理、スケジュール管理、売上管理など多くがアナログ作業で行われています。
        </p>
        <p>
          GIMMY
          は広範囲に及ぶ業務をデジタル化し生産性を高めるとともに、トレーナーの集客にも貢献するプロダクトとなっています。
        </p>
        <p>
        より多くの方に使って頂けるよう、利用状況を追いながら、プロダクトを改良して参ります。
        </p>
      </div>
    ),en:(
      <div className="grid gap-4">
        <p>
        Together with{' '}
          <a
            href="https://rascals.co.jp/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
           RASCAL's Co., Ltd.
          </a>
          , which focuses on sports and fitness-related services, we released "
          <a
            href="https://gimmy.co/"
            target={'_blank'}
            rel="noopener noreferrer"
            className=" text-primary-500 hover:underline"
          >
            GIMMY
          </a>
          " platform to digitize(DX) personal training operations.
        </p>
        <p>
        Many people in the industry are unfamiliar with IT, and there is still a lot of room for digitalization(DX).
        </p>
        <p>
        Tasks such as customer management, schedule management, and sales management are mostly done manually.
        </p>
        <p>
        GIMMY is a product that digitizes a wide range of business processes, increases productivity, and contributes to attracting trainers.
        </p>
        <p>
        We are continuously enhancing the product and keeping track of its usage to ensure it becomes more accessible to a much more wider audience.
        </p>
      </div>
    )},
    label: 'DX',
  },
];
