export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || "";

export const pageview = (url: string) => {
    window.gtag("config", GOOGLE_ANALYTICS_ID, {
        page_path: url,
    });
};

export const event = ({
  action,
  category,
  label,
  value,
}: {
    action: string;
    category: string;
    label: string;
    value?: number;
}): void => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};
