import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { MEMBERS, TOP_LEVEL } from 'constants/members';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'theme';
import DownIcon from '../../../../assets/down.svg';

interface BoxProps {
  title: string;
  withIcon?: boolean;
  rotateIcon?: boolean;
}

const QualityOfOrganization = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();
    const router = useRouter();

    const Divider = () => (
      <div className="m-auto h-[32px] w-1 border-l-[1px] border-dashed border-gray-secondary " />
    );

    const Box = ({ title, withIcon, rotateIcon }: BoxProps) => (
      <div
        className={`relative flex min-h-[60px] w-full items-center justify-center border border-gray-secondary ${
          !rotateIcon && withIcon ? 'mb-8' : 'mb-0'
        }`}
      >
        <Text
          className="!font-semibold text-gray-primary"
          {...typography.normal}
        >
          {t(title)}
        </Text>
        {withIcon && (
          <div
            className={`absolute right-6 transition-all ${
              rotateIcon ? 'rotate-0' : 'rotate-180'
            }`}
          >
            <DownIcon />
          </div>
        )}
      </div>
    );
    return (
      <section
        id={id}
        ref={ref}
        className="mt-[10px] pr-0 md:mb-16 lg:pr-[60px] xl:pr-[60px] 3xl:pr-0"
      >
        <div className="border-l-[6px] border-primary-500 pl-4">
          <Text
            as="h4"
            {...typography.h4}
            className="mb-4 text-gray-primary"
          >
            {t('Quality of Organization')}
          </Text>
          <Text
            className="whitespace-pre-line text-gray-secondary"
            {...typography.normal}
          >
            {router.locale === 'ja' ? (
              <>
                社名「WesionaryTEAM」が 「We are the Visionary Team」
                に由来しています。パートナーの事業ビジョンに伴走し、共に、より良い社会を実現するために集まっています。私たちの
                <Link href="/company" passHref>
                  <a className="text-primary-500 underline">
                    ビジョンとミッション
                  </a>
                </Link>
                、
                <Link href="/core-values" passHref>
                  <a className="text-primary-500 underline">
                    コア・バリュー
                  </a>
                </Link>
                を体現するため、推進体制を構築しています。採用に細心の注意を払い、継続的に啓蒙活動を行なっています。
              </>
            ) : (
              <>
                The company name "WesionaryTEAM" is derived from ‘We
                are the Visionary Team’. We come together to accompany
                our partners' business visions and work together to
                realize a better society. We are building a promotion
                system to embody our{' '}
                <Link href="/company" passHref>
                  <a className="text-primary-500 underline">
                    vision and mission
                  </a>
                </Link>
                , and{' '}
                <Link href="/core-values" passHref>
                  <a className="text-primary-500 underline">
                    core values
                  </a>
                </Link>
                . We pay close attention to recruitment and
                continuously educate our members.
              </>
            )}
            {t(
              '\n\nIn addition, we have established a Compliance Manual, and our Internal Control Department ensures that all members comply with laws, regulations, and social norms.',
            )}
          </Text>
        </div>
        <div className="relative mt-8 hidden md:block">
          <img
            src={`
              ${process.env.NEXT_PUBLIC_STATIC_URL}${
              router.locale === 'ja'
                ? '/organization/organization_jp.png'
                : '/organization/organization_en.png'
            }
            `}
            className="object-contain"
          />
        </div>
        <div className="block md:hidden">
          {TOP_LEVEL.map((department: string, index: number) => (
            <div key={index}>
              <Divider />
              <Box title={department} />
            </div>
          ))}
          <Divider />
          <Accordion defaultIndex={[0]} allowMultiple>
            {MEMBERS.map((peopleData, index: number) => (
              <AccordionItem key={index}>
                {({ isExpanded }: any) => (
                  <>
                    <AccordionButton>
                      <Box
                        title={t(peopleData.title)}
                        withIcon
                        rotateIcon={isExpanded}
                      />
                    </AccordionButton>
                    <AccordionPanel className="mb-8 border border-t-0 border-gray-secondary px-4 pt-4">
                      <UnorderedList className="mb-4">
                        {peopleData?.list?.map((list, key) =>
                          list === 'Backend' ||
                          list === 'Security' ? (
                            <ListItem
                              key={`list-${key}`}
                              className="text-gray-secondary"
                              {...typography.small}
                            >
                              {list}
                            </ListItem>
                          ) : (
                            <ListItem
                              key={`list-${key}`}
                              className="text-gray-secondary"
                              {...typography.small}
                            >
                              {t(list)}
                            </ListItem>
                          ),
                        )}
                      </UnorderedList>
                      {peopleData.people.map((people, index) => (
                        <div
                          key={`people-${index}`}
                          className="mb-4 flex gap-[10px]"
                        >
                          <div className="relative h-10 w-10">
                            <img
                              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/members${people.photo}`}
                            />
                          </div>
                          <div>
                            <Text {...typography.smallBold}>
                              {t(people.name)}
                            </Text>
                            {people.position === 'CEO' ? (
                              <Text {...typography.extraSmall}>
                                {people.position}
                              </Text>
                            ) : (
                              <Text {...typography.extraSmall}>
                                {t(people.position)}
                              </Text>
                            )}
                          </div>
                        </div>
                      ))}
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </section>
    );
  },
);

export { QualityOfOrganization };
