export const officialUrls={
    reactJs: 'https://reactjs.org/',
    nextJs: 'https://nextjs.org/',
    flutter: 'https://flutter.dev/',
    goLang: 'https://go.dev/',
    nodeJs: 'https://nodejs.org/en/',
    python: 'https://www.python.org/',
    gcp: 'https://cloud.google.com/',
    kubernetes: 'https://kubernetes.io/',
    aws: 'https://aws.amazon.com/',
    terraform: 'https://www.terraform.io/',
    mySql: 'https://www.mysql.com/',
    github: 'https://github.com',
    ciCd: 'https://resources.github.com/ci-cd/',
    slack: 'https://slack.com/',
    figma: 'https://figma.com/',
    jira: 'https://jira.atlassian.com/',
    notion: 'https://www.notion.so/',
    figJam: 'https://www.figma.com/figjam/',
    googleWorkspace: 'https://workspace.google.com/',
    openApi:'https://swagger.io/',
    browser: 'https://www.browserstack.com/',
    cleanArchitecture:""
}