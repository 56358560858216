import { Box, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'theme';
import { useRouter } from 'next/router';
import { AnimatedLink } from 'components/atoms';
import { Facebook, Linkedin, Medium } from 'public/assets/images/svg';

const Footer = () => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { pathname, asPath } = useRouter();

  const hoverListClasses =
    ' hover:underline cursor-pointer block mb-6';

  const activeClasses = 'underline font-semibold';

  return (
    <footer
      id="footer"
      className={`relative z-10 m-auto mt-16 flex items-center justify-center bg-gray-900 text-white md:pl-0 lg:${pathname === '/contact' ? 'pl-0' : 'pl-[20%]'
        }`}
    >
      <div
        className={`mx-6 max-w-[1040px] md:pt-[3rem] lg:mx-6 2xl:mx-0 3xl:max-w-[1440px] ${pathname === '/contact' && 'lg:max-w-[1440px]'
          }`}
      >
        <div className="mt-[32px] md:flex md:justify-between">
          <div
            style={{ ...typography.fontRubik, ...typography.h6 }}
            className="opacity-70 md:w-6/12"
          >
            <div className="md:w-[70%]">
              We are &nbsp;
              <span
                style={{ ...typography.fontRubik, ...typography.h5 }}
              >
                The Visionary Team,
              </span>
              <Text className="mt-2">
                {t(
                  'A team that accompanies our partners realize shared vision.',
                )}
              </Text>
            </div>
          </div>
          <div className="mt-[16px] opacity-70 md:mt-0 md:w-7/12">
            <Text {...typography.normal}>
              {t(
                'Co-creation is easier said than done. However, we strongly believe in the co-creation based on our many experiences, industry challenges, and market trends.',
              )}
            </Text>
            <Text {...typography.normal}>
              {t(
                'Aiming to be a team that accompanies our partners in the realization of their vision and DX, we pursue more efficient and effective forms of co-creation on a daily basis.',
              )}
            </Text>
          </div>
        </div>
        <hr className="mt-[16px] h-[1px] border-none bg-[#FFFFFF] text-[#FFFFFF] opacity-25 md:mt-[32px] md:mb-[50px]" />
        <div className="mt-[32px] justify-between md:flex  ">
          <div className="min-w-[180px]">
            <Text
              as="p"
              className={'mb-6'}
              {...typography.h5}
              {...typography.fontRubik}
            >
              {t('About')}
            </Text>
            <Link href="/company#company" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${asPath === '/company#company' ? activeClasses : ''
                  } capitalize`}
              >
                {t('Vision/Mission')}
              </Text>
            </Link>
            <Link href="/company#company-profile" passHref>
              <Text
                className={`${hoverListClasses} ${asPath === '/company#company-profile'
                  ? activeClasses
                  : ''
                  }`}
              >
                {t('Company Overview')}
              </Text>
            </Link>
            <Link href="/company#ceo" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${asPath === '/company#ceo' ? activeClasses : ''
                  } capitalize`}
              >
                {t('Member Introduction')}
              </Text>
            </Link>
            <Link href="/company#office" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${asPath === '/company#office' ? activeClasses : ''
                  }`}
              >
                {t('Offices')}
              </Text>
            </Link>
            <Link href="/corporate-philosophy" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/corporate-philosophy'
                  ? activeClasses
                  : ''
                  } capitalize`}
              >
                {t('Corporate philosophy')}
              </Text>
            </Link>
          </div>
          <div className="mt-[32px]  md:mt-0 md:min-w-[180px]">
            <Text
              className="mb-6"
              {...typography.h5}
              {...typography.fontRubik}
            >
              {t('Service')}
            </Text>

            <Link href="/service" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/service' ? activeClasses : ''
                  } capitalize`}
              >
                {t('Service details')}
              </Text>
            </Link>

            <Link href="/technology" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/technology' ? activeClasses : ''
                  }`}
              >
                {t('Technology')}
              </Text>
            </Link>

            <Link href="/quality" passHref>
              <Text
                className={`${hoverListClasses} ${pathname === '/quality' ? activeClasses : ''
                  } capitalize`}
                as="a"
              >
                {t('Quality assurance')}
              </Text>
            </Link>

            <Link href="/security" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/security' ? activeClasses : ''
                  }`}
              >
                {t('Security')}
              </Text>
            </Link>
            <Link href="/alliance" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/alliance' ? activeClasses : ''
                  }`}
              >
                {t('Alliance')}
              </Text>
            </Link>
          </div>

          <div className="mt-[32px]   md:mt-0 md:min-w-[115px]">
            <Text
              className="mb-6"
              {...typography.h5}
              {...typography.fontRubik}
            >
              {t('Career')}
            </Text>

            <Link href="/career" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/career' ? activeClasses : ''
                  } capitalize`}
              >
                {t('Career details')}
              </Text>
            </Link>
            <Link href="/core-values" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/core-values' ? activeClasses : ''
                  } capitalize`}
              >
                {t('Core value')}
              </Text>
            </Link>
            <Link href="/welfare" passHref>
              <Text
                as="a"
                className={`${hoverListClasses} ${pathname === '/welfare' ? activeClasses : ''
                  }`}
              >
                {t('Benefits')}
              </Text>
            </Link>
            <div className="mt-[62px] flex gap-[24px]">
              <div className="">
                <AnimatedLink href="https://www.facebook.com/wesionaryteam">
                  <Facebook />
                </AnimatedLink>
              </div>
              <div>
                <AnimatedLink href="https://www.linkedin.com/company/wesionary-team">
                  <Linkedin />
                </AnimatedLink>
              </div>
              <div>
                <AnimatedLink href="https://articles.wesionary.team/">
                  <Medium />
                </AnimatedLink>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-[24px] h-[1px] border-none bg-[#FFFFFF] text-[#FFFFFF] opacity-25 md:mt-[50px] md:mb-[30px]" />
        <div className="flex flex-col flex-wrap gap-[32px] text-left md:order-last md:flex-col-reverse md:justify-between md:text-right">
            <Box
              display="flex"
              gap={isMobile ? '18px' : '32px'}
              justifyContent="space-between"
              className="flex-col flex-wrap md:flex-row"
            >
              <Link href="/contact" passHref>
                <a
                  className={`text-sm hover:underline md:self-end ${pathname === '/contact' ? 'font-semibold' : ''
                    }`}
                >
                  {t('Contact Us')}
                </a>
              </Link>

              <Link href="/privacy-policy" passHref>
                <Text
                  as="a"
                  className={`text-sm hover:cursor-pointer hover:underline ${pathname === '/privacy-policy'
                    ? 'font-semibold'
                    : ''
                    } capitalize`}
                >
                  {t('Privacy policy')}
                </Text>
              </Link>
              <Link href="/information-security-policy" passHref>
                <Text
                  as="a"
                  className={`text-sm  hover:cursor-pointer hover:underline ${pathname === '/information-security-policy'
                    ? 'font-semibold'
                    : ''
                    } capitalize`}
                >
                  {t('Information Security Basic Policy')}
                </Text>
              </Link>
              <Link href="/report-security" passHref>
                <Text
                  as="a"
                  className={`text-sm hover:cursor-pointer hover:underline  ${pathname === '/report-security'
                    ? 'font-semibold'
                    : ''
                    } capitalize`}
                >
                  {t('Report security issue')}
                </Text>
              </Link>
              <Link href="/quality-policy" passHref>
                <Text
                  as="a"
                  className={`text-sm hover:cursor-pointer hover:underline  ${pathname === '/report-security'
                    ? 'font-semibold'
                    : ''
                    } capitalize`}
                >
                  {t('Quality Policy')}
                </Text>
              </Link>
            </Box>
        </div>
        <hr className="my-[24px] h-[1px] border-none bg-[#FFFFFF] text-[#FFFFFF] opacity-25 md:mt-[50px] md:mb-[30px]" />
        <div className='mb-[32px]'>
          <p className="flex flex-col flex-wrap gap-[8px] text-xs opacity-70 md:order-first lg:self-start">
            <span className='flex items-start'>
              © 2022 wesionaryTEAM Co. Ltd. All rights reserved.
            </span>
            <span>
              {t("Information Security and Quality Management Certifications: ISO/IEC 27001:2022, ISO 9001:2015")}
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
