import { Text } from '@chakra-ui/react';
import Link from 'next/link';
import LinkIcon from 'public/news/link.svg';

type Props = {
  id: string;
  img: string;
  link: string;
  title: string;
  date: string;
  label: string;
  slug: string;
};

export const NewsCard = (props: Props) => {
  const { img, title, date, label, slug } = props;

  return (
    <Link href={`/news/${slug}`} passHref>
      <a className="group flex cursor-pointer flex-col transition duration-200  ease-in sm:max-w-full">
        <div className="container relative">
          <div className="absolute top-4 right-4 z-10 hidden group-hover:block">
            <LinkIcon />
          </div>
          <img
            src={
              img
                ? `${process.env.NEXT_PUBLIC_STATIC_URL}/news/${img}`
                : `${process.env.NEXT_PUBLIC_STATIC_URL}/news/fallback.png`
            }
            className="group-hover:z-120 m-auto h-[230px] max-h-[200px] w-full
             transform border border-solid border-gray-500 object-cover object-center  transition duration-200  ease-in group-hover:z-50 group-hover:opacity-80"
            alt="news"
          />
          <div className="textblock absolute bottom-4 left-2 bg-white p-2 ">
            <Text className="text-base font-semibold text-black-secondary">
              {label}
            </Text>
          </div>
        </div>
        <Text className="mt-[8px] text-base text-gray-secondary">
          {date}
        </Text>
        <Text className="mt-[8px] text-lg font-semibold decoration-solid line-clamp-2 group-hover:underline">
          {title}
        </Text>
      </a>
    </Link>
  );
};
